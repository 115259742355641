import { gql } from '@apollo/client';
import {
  IOffer, IProduct, IProductGroup,
} from './model/interfaces';

export const GET_DEFAULT_OFFER = gql`
  query getDefaultOffers ($id1: ID!) {
    offer1: getProduct(productID: $id1) {
      id
      name
      businessUnit
      businessUnitId
      vertical
      verticalId
      isSubscription
      type
      defaultOffer {
        id
        maxPurchases
        token
        plans(productID: $id1) {
          id
          discount
          installmentCount: installment
          installmentAmount
          enrollmentAmount
          remarkedEnrollmentAmount
          remarkedInstallmentAmount
          programAmount
          currency
        }
      }
    }
  }
`;

export const GET_DEFAULT_OFFERS = gql`
  query getDefaultOffers ($id1: ID!, $id2: ID!) {
    offer1: getProduct(productID: $id1) {
      id
      name
      businessUnit
      businessUnitId
      vertical
      verticalId
      isSubscription
      type
      defaultOffer {
        id
        maxPurchases
        token
        plans(productID: $id1) {
          id
          discount
          installmentCount: installment
          installmentAmount
          enrollmentAmount
          remarkedEnrollmentAmount
          remarkedInstallmentAmount
          programAmount
          currency
        }
      }
    }

    offer2: getProduct(productID: $id2) {
      id
      name
      businessUnit
      businessUnitId
      vertical
      verticalId
      isSubscription
      type
      defaultOffer {
        id
        maxPurchases
        token
        plans(productID: $id2) {
          id
          discount
          installmentCount: installment
          installmentAmount
          enrollmentAmount
          remarkedEnrollmentAmount
          remarkedInstallmentAmount
          programAmount
          currency
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
    query getProducts($id1: ID!, $country: String) {
      products1: getAllEvents(productID: $id1, country: $country) {
        id
        code
        startsAt
        endsAt: endDate
        lastplaces
        soldout
        quota
        duration {
          name,
          id
        }
        externalAdmissionId
        slotFrom
        slotTo
        schedules {
          day,
          startTime,
          endTime
        }
      }
    }
`;

export const GET_PRODUCTS = gql`
    query getProducts($id1: ID!, $id2: ID!, $country: String) {
      products1: getAllEvents(productID: $id1, country: $country) {
        id
        code
        startsAt
        endsAt: endDate
        lastplaces
        soldout
        quota
        duration {
          name,
          id
        }
        externalAdmissionId
        slotFrom
        slotTo
        schedules {
          day,
          startTime,
          endTime
        }
      }

      products2: getAllEvents(productID: $id2, country: $country) {
        id
        code
        startsAt
        endsAt: endDate
        lastplaces
        soldout
        quota
        duration {
          name,
          id
        }
        externalAdmissionId
        slotFrom
        slotTo
        schedules {
          day,
          startTime,
          endTime
        }
      }
    }  
`;

const OFFER1 = `
    offer1: getOffer(token: $token1, productID: $id1, country: $country) {
      id
      token
      maxPurchases
      plans(productID: $id1, country: $country) {
        id
        discount
        installmentCount: installment
        installmentAmount
        enrollmentAmount
        remarkedEnrollmentAmount
        remarkedInstallmentAmount
        programAmount
        currency
      }
    }
`;

const OFFER2 = `
    offer2: getOffer(token: $token2, productID: $id2, country: $country) {
      id
      token
      maxPurchases
      plans(productID: $id2, country: $country) {
        id
        discount
        installmentCount: installment
        installmentAmount
        enrollmentAmount
        remarkedEnrollmentAmount
        remarkedInstallmentAmount
        programAmount
        currency
      }
    }
`;

export const GET_OFFER = gql`
  query getOffer($token1: String, $id1: ID!, $country: String) {
    ${OFFER1}
  }
`;

export const GET_OFFERS = gql`
  query getOffer($token1: String, $token2: String, $id1: ID!, $id2: ID!, $country: String) {
    ${OFFER1}

    ${OFFER2}
  }
`;

export interface GetProductEventsData {
  getAllEvents: IProductGroup[];
  getProduct: IProduct;
  getOffer: IOffer;
}
