export const defaultColorKey = 'blue';

export type Color = {
  text: string;
  textContrast: string;
  border: string;
  bg: string;
  bgHover: string;
};

const colorText = {
  green: {
    200: 'text-green-200',
    400: 'text-green-400',
    500: 'text-green-500',
    600: 'text-green-600',
    800: 'text-green-800',
  },
  yellow: {
    200: 'text-yellow-200',
    400: 'text-yellow-400',
    500: 'text-yellow-500',
    600: 'text-yellow-600',
    800: 'text-yellow-800',
  },
  yellowGreen: {
    200: 'text-yellowGreen-200',
    400: 'text-yellowGreen-400',
    500: 'text-yellowGreen-500',
    600: 'text-yellowGreen-600',
    800: 'text-yellowGreen-800',
  },
  deepPurple: {
    200: 'text-deepPurple-200',
    400: 'text-deepPurple-400',
    500: 'text-deepPurple-500',
    600: 'text-deepPurple-600',
    800: 'text-deepPurple-800',
  },
  violet: {
    200: 'text-violet-200',
    400: 'text-violet-400',
    500: 'text-violet-500',
    600: 'text-violet-600',
    800: 'text-violet-800',
  },
  coral: {
    200: 'text-coral-200',
    400: 'text-coral-400',
    500: 'text-coral-500',
    600: 'text-coral-600',
    800: 'text-coral-800',
  },
  blue: {
    200: 'text-blue-200',
    400: 'text-blue-400',
    500: 'text-blue-500',
    600: 'text-blue-600',
    800: 'text-blue-800',
  },
  lime: {
    200: 'text-lime-200',
    400: 'text-lime-400',
    500: 'text-lime-500',
    600: 'text-lime-600',
    800: 'text-lime-800',
  },
  orange: {
    200: 'text-orange-200',
    400: 'text-orange-400',
    500: 'text-orange-500',
    600: 'text-orange-600',
    800: 'text-orange-800',
  },
  purple: {
    200: 'text-purple-200',
    400: 'text-purple-400',
    500: 'text-purple-500',
    600: 'text-purple-600',
    800: 'text-purple-800',
  },
  pink: {
    200: 'text-pink-200',
    400: 'text-pink-400',
    500: 'text-pink-500',
    600: 'text-pink-600',
    800: 'text-pink-800',
  },
  emerald: {
    200: 'text-emerald-200',
    400: 'text-emerald-400',
    500: 'text-emerald-500',
    600: 'text-emerald-600',
    800: 'text-emerald-800',
  },
  white: {
    600: 'text-white-1',
  },
  black: {
    500: 'text-black-1',
    600: 'text-black-2',
  },
};

const colorBg = {
  green: {
    200: 'bg-green-200',
    400: 'bg-green-400',
    500: 'bg-green-500',
    600: 'bg-green-600',
    800: 'bg-green-800',
  },
  yellow: {
    200: 'bg-yellow-200',
    400: 'bg-yellow-400',
    500: 'bg-yellow-500',
    600: 'bg-yellow-600',
    800: 'bg-yellow-800',
  },
  yellowGreen: {
    200: 'bg-yellowGreen-200',
    400: 'bg-yellowGreen-400',
    500: 'bg-yellowGreen-500',
    600: 'bg-yellowGreen-600',
    800: 'bg-yellowGreen-800',
  },
  deepPurple: {
    200: 'bg-deepPurple-200',
    400: 'bg-deepPurple-400',
    500: 'bg-deepPurple-500',
    600: 'bg-deepPurple-600',
    800: 'bg-deepPurple-800',
  },
  violet: {
    200: 'bg-violet-200',
    400: 'bg-violet-400',
    500: 'bg-violet-500',
    600: 'bg-violet-600',
    800: 'bg-violet-800',
  },
  coral: {
    200: 'bg-coral-200',
    400: 'bg-coral-400',
    500: 'bg-coral-500',
    600: 'bg-coral-600',
    800: 'bg-coral-800',
  },
  blue: {
    200: 'bg-blue-200',
    400: 'bg-blue-400',
    500: 'bg-blue-500',
    600: 'bg-blue-600',
    800: 'bg-blue-800',
  },
  lime: {
    200: 'bg-lime-200',
    400: 'bg-lime-400',
    500: 'bg-lime-500',
    600: 'bg-lime-600',
    800: 'bg-lime-800',
  },
  orange: {
    200: 'bg-orange-200',
    400: 'bg-orange-400',
    500: 'bg-orange-500',
    600: 'bg-orange-600',
    800: 'bg-orange-800',
  },
  purple: {
    200: 'bg-purple-200',
    400: 'bg-purple-400',
    500: 'bg-purple-500',
    600: 'bg-purple-600',
    800: 'bg-purple-800',
  },
  pink: {
    200: 'bg-pink-200',
    400: 'bg-pink-400',
    500: 'bg-pink-500',
    600: 'bg-pink-600',
    800: 'bg-pink-800',
  },
  emerald: {
    200: 'bg-emerald-200',
    400: 'bg-emerald-400',
    500: 'bg-emerald-500',
    600: 'bg-emerald-600',
    800: 'bg-emerald-800',
  },
  white: {
    600: 'bg-white-1',
  },
  black: {
    500: 'bg-black-1',
    600: 'bg-black-2',
  },
};

const colorBgHover = {
  green: {
    200: 'hover:bg-green-200',
    400: 'hover:bg-green-400',
    500: 'hover:bg-green-500',
    600: 'hover:bg-green-600',
    800: 'hover:bg-green-800',
  },
  yellow: {
    200: 'hover:bg-yellow-200',
    400: 'hover:bg-yellow-400',
    500: 'hover:bg-yellow-500',
    600: 'hover:bg-yellow-600',
    800: 'hover:bg-yellow-800',
  },
  yellowGreen: {
    200: 'hover:bg-yellowGreen-200',
    400: 'hover:bg-yellowGreen-400',
    500: 'hover:bg-yellowGreen-500',
    600: 'hover:bg-yellowGreen-600',
    800: 'hover:bg-yellowGreen-800',
  },
  deepPurple: {
    200: 'hover:bg-deepPurple-200',
    400: 'hover:bg-deepPurple-400',
    500: 'hover:bg-deepPurple-500',
    600: 'hover:bg-deepPurple-600',
    800: 'hover:bg-deepPurple-800',
  },
  violet: {
    200: 'hover:bg-violet-200',
    400: 'hover:bg-violet-400',
    500: 'hover:bg-violet-500',
    600: 'hover:bg-violet-600',
    800: 'hover:bg-violet-800',
  },
  coral: {
    200: 'hover:bg-coral-200',
    400: 'hover:bg-coral-400',
    500: 'hover:bg-coral-500',
    600: 'hover:bg-coral-600',
    800: 'hover:bg-coral-800',
  },
  blue: {
    200: 'hover:bg-blue-200',
    400: 'hover:bg-blue-400',
    500: 'hover:bg-blue-500',
    600: 'hover:bg-blue-600',
    800: 'hover:bg-blue-800',
  },
  lime: {
    200: 'hover:bg-lime-200',
    400: 'hover:bg-lime-400',
    500: 'hover:bg-lime-500',
    600: 'hover:bg-lime-600',
    800: 'hover:bg-lime-800',
  },
  orange: {
    200: 'hover:bg-orange-200',
    400: 'hover:bg-orange-400',
    500: 'hover:bg-orange-500',
    600: 'hover:bg-orange-600',
    800: 'hover:bg-orange-800',
  },
  purple: {
    200: 'hover:bg-purple-200',
    400: 'hover:bg-purple-400',
    500: 'hover:bg-purple-500',
    600: 'hover:bg-purple-600',
    800: 'hover:bg-purple-800',
  },
  pink: {
    200: 'hover:bg-pink-200',
    400: 'hover:bg-pink-400',
    500: 'hover:bg-pink-500',
    600: 'hover:bg-pink-600',
    800: 'hover:bg-pink-800',
  },
  emerald: {
    200: 'hover:bg-emerald-200',
    400: 'hover:bg-emerald-400',
    500: 'hover:bg-emerald-500',
    600: 'hover:bg-emerald-600',
    800: 'hover:bg-emerald-800',
  },
  white: {
    600: 'hover-white-1',
  },
  black: {
    500: 'hover-black-1',
    600: 'hover-black-2',
  },
};

const colorBorder = {
  green: {
    200: 'border-green-200',
    400: 'border-green-400',
    500: 'border-green-500',
    600: 'border-green-600',
    800: 'border-green-800',
  },
  yellow: {
    200: 'border-yellow-200',
    400: 'border-yellow-400',
    500: 'border-yellow-500',
    600: 'border-yellow-600',
    800: 'border-yellow-800',
  },
  yellowGreen: {
    200: 'border-yellowGreen-200',
    400: 'border-yellowGreen-400',
    500: 'border-yellowGreen-500',
    600: 'border-yellowGreen-600',
    800: 'border-yellowGreen-800',
  },
  deepPurple: {
    200: 'border-deepPurple-200',
    400: 'border-deepPurple-400',
    500: 'border-deepPurple-500',
    600: 'border-deepPurple-600',
    800: 'border-deepPurple-800',
  },
  violet: {
    200: 'border-violet-200',
    400: 'border-violet-400',
    500: 'border-violet-500',
    600: 'border-violet-600',
    800: 'border-violet-800',
  },
  coral: {
    200: 'border-coral-200',
    400: 'border-coral-400',
    500: 'border-coral-500',
    600: 'border-coral-600',
    800: 'border-coral-800',
  },
  blue: {
    200: 'border-blue-200',
    400: 'border-blue-400',
    500: 'border-blue-500',
    600: 'border-blue-600',
    800: 'border-blue-800',
  },
  lime: {
    200: 'border-lime-200',
    400: 'border-lime-400',
    500: 'border-lime-500',
    600: 'border-lime-600',
    800: 'border-lime-800',
  },
  orange: {
    200: 'border-orange-200',
    400: 'border-orange-400',
    500: 'border-orange-500',
    600: 'border-orange-600',
    800: 'border-orange-800',
  },
  purple: {
    200: 'border-purple-200',
    400: 'border-purple-400',
    500: 'border-purple-500',
    600: 'border-purple-600',
    800: 'border-purple-800',
  },
  pink: {
    200: 'border-pink-200',
    400: 'border-pink-400',
    500: 'border-pink-500',
    600: 'border-pink-600',
    800: 'border-pink-800',
  },
  emerald: {
    200: 'border-emerald-200',
    400: 'border-emerald-400',
    500: 'border-emerald-500',
    600: 'border-emerald-600',
    800: 'border-emerald-800',
  },
  white: {
    600: 'border-white-1',
  },
  black: {
    500: 'border-black-1',
    600: 'border-black-2',
  },
};

const colorTextContrast = {
  blue: 'text-white-1',
  coral: 'text-white-1',
  emerald: 'text-gray-1',
  green: 'text-white-1',
  lime: 'text-gray-1',
  orange: 'text-gray-1',
  pink: 'text-white-1',
  purple: 'text-white-1',
  yellow: 'text-gray-1',
  yellowGreen: 'text-gray-1',
  deepPurple: 'text-white-1',
  violet: 'text-white-1',
  white: 'text-black-1',
  black: 'text-white-1',
};

export const colors = {
  text: colorText,
  textContrast: colorTextContrast,
  bg: colorBg,
  bgHover: colorBgHover,
  border: colorBorder,
};

export enum ColorDefault {
  text = 'text-primary',
  textContrast = 'text-white',
  border = 'border-primary',
  bg = 'bg-primary',
  bgHover = 'hover:bg-primary-800',
}

type GetColor = (key?: string, scale?: number, scaleHover?: number) => Color;

export const getColor: GetColor = (colorKey = defaultColorKey, scale = 500, scaleHover = 800) => {
  const key = colors.bg[colorKey] ? colorKey : defaultColorKey;

  return {
    bg: colors.bg[key] && colors.bg[key][scale] ? colors.bg[key][scale] : ColorDefault.bg,
    bgHover:
      colors.bgHover[key] && colors.bgHover[key][scaleHover] ? colors.bgHover[key][scaleHover] : ColorDefault.bgHover,
    border: colors.border[key] && colors.border[key][scale] ? colors.border[key][scale] : ColorDefault.border,
    text: colors.text[key] && colors.text[key][scale] ? colors.text[key][scale] : ColorDefault.text,
    textContrast: colors.textContrast[key] ?? ColorDefault.textContrast,
  };
};
