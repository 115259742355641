import { gql } from '@apollo/client';
import { TFunctionQuery } from './model/interfaces';

export const getOffersCheckout: TFunctionQuery = () => gql`
  query GetOffer($ware: ID!, $token: String!, $currency: String, $showcase: Int) {
    getOffer(ware: $ware, token: $token, currency: $currency, showcase: $showcase) {
      name
      start_at
      end_at
      plans {
        id
        billing_range
        total_amount
        subtotal
        discount
        nominal_discount_amount
        financial_installments
        installment
        financial_installments
        financial_amount
        amount
        remarked
        checkout_url
        showcase_order
      }
      ware {
        product {
          cluster {
            alias
            vertical {
              id
              name
            }
            business_unit {
              id
              name
            }
          }
        }
      }
    }
  }
`;
