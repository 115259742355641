import { useContext } from 'react';
import { Analytics } from '@segment/analytics-next';
import { env } from 'process';
import { AnalyticsContext } from '../contexts/AnalyticsContext';
import { TrackingPayload } from '../types/tracking.type';
import { identify, page, track } from '../utils/analytics';
import { delay } from '../utils/common';

export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  const promise = result ?? [];

  if (!result && typeof window !== 'undefined' && env.NODE_ENV !== 'test') {
    throw new Error('Context used outside of its Provider!');
  }

  return {
    page: async (category?: any, name?: any, properties?: any) => {
      const analytics: Analytics = (await promise)[0];
      page(analytics, category, name, properties);
    },
    track: async (eventName: string, payload: TrackingPayload, locale: string, hasDelay = false) => {
      const analytics: Analytics = (await promise)[0];

      if (hasDelay) await delay(1000);

      track(analytics, eventName, payload, locale);
    },
    identify: async (payload: object) => {
      const analytics: Analytics = (await promise)[0];
      identify(analytics, payload);
    },
    addSourceMiddleware: async (callback: any) => {
      const analytics: Analytics = (await promise)[0];
      analytics.addSourceMiddleware(callback);
    },
  };
};
