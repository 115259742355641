import { FC } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import LinkWithQuery from '../../../components/LinkWithQuery';
import { IPricingCards, IPricingCardDynamic } from '../Pricing.types';

const PricingEmpty: FC<IPricingCards | IPricingCardDynamic> = ({ slice }) => (
  <section className="relative overflow-hidden h-screen md:h-101">
    <div className="w-full absolute top-0 left-0 max-h-161">
      {slice.primary?.image?.url && (
        <div className="w-full h-screen absolute z-0 md:h-101">
          <Image
            className="object-cover h-screen w-full"
            src={slice.primary.image.url}
            alt={slice.primary.image.alt}
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>
      )}
      <div className="w-full absolute top-0 left-0 z-0 bg-black-2/80 h-screen md:h-101" />
    </div>
    <div className="dh-content relative z-0 flex flex-col justify-center items-center h-full">
      {slice.primary?.titleAlt && (
        <h1
          className={classNames(
            'text-athensGray text-center text-4xl font-semibold leading-15',
            'md:text-5xl md:font-bold',
          )}
        >
          {slice.primary.titleAlt}
        </h1>
      )}

      <LinkWithQuery
        href={slice.primary.urlAlt?.url}
        target={slice.primary.urlAlt?.target ?? '_blank'}
        className={classNames(
          'max-w-sm px-10 py-2 border border-transparent text-center m-x-auto mt-20 text-base leading-7',
          'font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700',
        )}
      >
        {slice.primary.btnLabelAlt}
      </LinkWithQuery>

    </div>
  </section>
);

export default PricingEmpty;
