import {
  FC, ReactNode, useCallback, useContext, useEffect, useState,
} from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import Image from 'next/image';
import clsx from 'classnames';
import { useRouter } from 'next/router';
import { useCheckoutQueryParams } from 'hooks/useCheckoutQueryParams';
import { getUtm } from '../../../utils/analytics';
import { NextStepFlow, TrackEvents } from '../../../utils/constants';
import { useAnalytics } from '../../../hooks/useAnalytics';
import BackgroundTheme from '../../../components/BackgroundTheme/BackgroundTheme';
import useLocale from '../../../hooks/useLocale';
import { IOfferShowcase, IPlan, IPricingCardDynamic } from '../Pricing.types';
import { getColor } from '../../../utils/colors';
import { addQueryParamsToCheckoutUrl, getPlansByShowcase } from '../../../utils/checkout';
import { AppContext } from '../../../contexts/AppContext';
import useGeoCountry from '../../../hooks/useGeoCountry';
import { getExperiments } from '../../../utils/optimize';
import PricingEmpty from './PricingEmpty';

export const formatCurrency = (
  alias: string,
  amount: number,
  locale: string,
): ReactNode => {
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'decimal',
  }).format(amount);

  return `${alias} ${formattedAmount}`;
};

const CURRENCY_CODE_USD = 'USD';

const replaceVars = (
  input: string | RichTextBlock[],
  plan: IPlan,
  locale: string,
  currency: string,
): string | RichTextBlock[] => {
  const replaceTemplate = (text: string): string => text.replace(/{{(.*?)}}/g, (_, key) => {
    const value = plan[key as keyof IPlan];

    if (typeof value === 'number' && key !== 'discount') {
      return formatCurrency(currency, value, locale) as string;
    }

    return value?.toString() ?? '';
  });

  if (typeof input === 'string') {
    return replaceTemplate(input);
  }

  return input.map((block) => (block.text
    ? {
      ...block,
      text: replaceTemplate(block.text),
    }
    : block));
};

const PricingCardDynamic: FC<IPricingCardDynamic> = ({ slice }) => {
  const { state } = useContext(AppContext);
  const currencySelected = state.currency.selected;
  const [offers, setOffers] = useState<Record<string, IOfferShowcase | null>>({});
  const [plans, setPlans] = useState<IPlan[]>([]);
  const locale = useLocale();
  const color = getColor(slice.primary.color);
  const { track } = useAnalytics();
  const geoCountry = useGeoCountry();
  const experiments = getExperiments();
  const router = useRouter();
  const utm = getUtm();
  const queryParams = useCheckoutQueryParams();

  useEffect(() => {
    if (!currencySelected) return;

    setOffers({});
    setPlans([]);

    const fetchPlanByShowcase = async () => {
      const offerPromises = slice?.items?.map(async (item) => {
        const offerData = await getPlansByShowcase({
          ware: item.wareId,
          token: item.token,
          currency: currencySelected,
          showcase: item.showcase,
        });
        setPlans(offerData?.plans ?? []);
        return { key: `${item.wareId}-${item.token}`, offer: offerData };
      });

      if (offerPromises) {
        const offerResults = await Promise.all(offerPromises);
        const offerMap = offerResults.reduce(
          (acc, { key, offer }) => ({ ...acc, [key]: offer }),
          {},
        );

        setOffers(offerMap);
      }
    };

    fetchPlanByShowcase();
  }, [slice.items, currencySelected]);

  const getVariantCss = (variant: string) => {
    switch (variant) {
      case 'primary':
        return 'pricing-card-dynamic-card__button--primary';
      case 'secondary':
        return 'pricing-card-dynamic-card__button--secondary';
      case 'tertiary':
        return 'pricing-card-dynamic-card__button--tertiary';
      default:
        return '';
    }
  };

  const handleClick = useCallback(({
    offer,
    label = '',
  }: {
    offer: IOfferShowcase;
    label?: string;
  }): void => {
    const trackingContext = {
      platform: 'Web',
      slice: 'pricing',
      slice_variant: 'pricingCardDynamic',
      next_step: NextStepFlow.checkout,
      label,
      destination_url: offer?.plans[0]?.checkoutUrl ?? '',
    };
    const trackingProduct = {
      name: offer?.name ?? '',
      start_date: offer?.startAt ?? '',
      end_date: offer?.endAt ?? '',
      id: offer?.plans[0]?.id ?? '',
      code: offer?.ware?.product[0]?.cluster.alias ?? '',
      vertical_id: offer?.ware?.product[0]?.cluster?.vertical?.id ?? '',
      vertical: offer?.ware?.product[0]?.cluster?.vertical?.name ?? '',
      business_unit_id: offer?.ware?.product[0]?.cluster?.businessUnit?.id ?? '',
      business_unit: offer?.ware?.product[0]?.cluster?.businessUnit?.name ?? '',
    };
    const gclidKey = 'gclid';
    const gclid = (router?.query[gclidKey] as string) || undefined;
    const trackingPayload = {
      context: trackingContext,
      experiments,
      geo_country: geoCountry,
      product: trackingProduct,
      tracking: {
        gclid,
        utm: Object.keys(utm).length ? utm : undefined,
      },
    };

    track(TrackEvents.productApplicationClicked, trackingPayload, locale);

    const checkoutUrl = offer?.plans[0]?.checkoutUrl;

    if (checkoutUrl) {
      const checkoutUrlWithParams = addQueryParamsToCheckoutUrl(checkoutUrl, queryParams);
      window.location.href = checkoutUrlWithParams;
    }
  }, [experiments, geoCountry, locale, track, router?.query, utm, queryParams]);

  if (!plans.length && slice?.primary?.btnLabelAlt && slice?.primary?.urlAlt
    && CURRENCY_CODE_USD === currencySelected) return <PricingEmpty slice={slice} />;
  if (!plans.length) return null;

  return (
    <BackgroundTheme pattern="pattern1" theme={slice.primary.color}>
      <section className="pricing-card-dynamic dh-content-full">
        {/* Primary section */}
        <div>
          {slice.primary.title && <h2 className="pricing-card-dynamic__title">{slice.primary.title}</h2>}
          {slice.primary.description && (
            <div className="pricing-card-dynamic__description">
              <RichText render={slice.primary.description} />
            </div>
          )}
        </div>
        {/* Cards Section */}
        <div className="pricing-card-dynamic-layout">
          {slice?.items?.map((item) => {
            const offer = offers[`${item.wareId}-${item.token}`];
            if (!offer) return null;

            const plan = offer?.plans?.[0];
            if (!plan) return null;

            const mappedItem = {
              ...item,
              pill: item.pill ? replaceVars(item.pill, plan, locale, currencySelected) : '',
              discount: item.discount ? replaceVars(item.discount, plan, locale, currencySelected) : '',
              total: item.total ? replaceVars(item.total, plan, locale, currencySelected) : [],
              installments: item.installments
                ? replaceVars(item.installments, plan, locale, currencySelected)
                : '',
              totalFinal: item.totalFinal
                ? replaceVars(item.totalFinal, plan, locale, currencySelected)
                : '',
              installmentsAmount: item.installmentsAmount
                ? replaceVars(item.installmentsAmount, plan, locale, currencySelected)
                : '',
            };

            const isHighlighted = mappedItem?.highlighted;
            const buttonClass = isHighlighted
              ? 'pricing-card-dynamic-card__button--selected'
              : 'pricing-card-dynamic-card__button--not-selected';
            const variantButton = getVariantCss(mappedItem?.buttonVariant ?? '');

            return (
              <div
                key={item.wareId + item.token}
                className={clsx(
                  'pricing-card-dynamic-card',
                  isHighlighted
                    ? `pricing-card-dynamic-card--selected ${color.border} ${color.bg}`
                    : 'pricing-card-dynamic-card--not-selected',
                )}
              >
                {/* Highlight banner */}
                {isHighlighted && (
                  <div className={clsx(
                    'pricing-card-dynamic-card__recommended',
                    color.textContrast,
                    color.bg,
                  )}
                  >
                    {mappedItem?.highlightedLabel}
                  </div>
                )}
                {/* Card Content */}
                <div
                  className={clsx(
                    'pricing-card-dynamic-card__content',
                    isHighlighted
                      ? 'pricing-card-dynamic-card__content--selected'
                      : 'pricing-card-dynamic-card__content--not-selected',
                  )}
                >
                  <div>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-col items-baseline">
                        <p className="pricing-card-dynamic-card__type">{mappedItem?.type}</p>
                        {mappedItem.logo?.url ? (
                          <Image
                            width={mappedItem?.logo?.dimensions?.width}
                            height={mappedItem?.logo?.dimensions?.height}
                            src={mappedItem?.logo?.url}
                            alt={mappedItem?.logo?.alt}
                            className="pricing-card-dynamic-card__icon"
                          />
                        ) : (
                          <h3 className="pricing-card-dynamic-card__title">{mappedItem?.title}</h3>
                        )}

                      </div>
                      <div className="flex flex-col justify-start">
                        {mappedItem.pill && (
                          <span
                            className={`pricing-card-dynamic-card__discount ${color.textContrast} ${color.bg}`}
                          >
                            {mappedItem?.pill}
                          </span>
                        )}
                      </div>
                    </div>
                    {mappedItem?.description && (
                      <div
                        className="flex flex-col justify-start items-start gap-4 py-4"
                      >
                        <p className="pricing-card-dynamic-card__text-muted-item-white">{mappedItem?.description}</p>
                        <div className="pricing-card-dynamic-card__divider" />
                      </div>
                    )}
                    <div className="space-y-2 ">
                      <p className="pricing-card-dynamic-card__text-muted">
                        {(mappedItem?.total as RichTextBlock[])?.map((block) => (block.text
                          ? block.text.split(new RegExp(`(${currencySelected} \\d+[\\d.,]*)`))
                            .map((part) => (new RegExp(`^${currencySelected} \\d+[\\d.,]*$`).test(part) ? (
                              <span key={`${block.text}`} className="line-through">
                                {part}
                              </span>
                            ) : (
                              <span key={`${block.text}`}>{part}</span>
                            )))
                          : null))}
                      </p>
                      <p className="pricing-card-dynamic-card__text-highlight">{mappedItem?.discount}</p>
                      <p className="pricing-card-dynamic-card__text-muted-item-white">{mappedItem?.installments}</p>
                      <p className="pricing-card-dynamic-card__text-bold">
                        {mappedItem?.installmentsAmount}
                      </p>
                      <p className="pricing-card-dynamic-card__text-muted-item">{mappedItem?.totalFinal}</p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`pricing-card-dynamic-card__button ${mappedItem?.buttonVariant
                      ? `${variantButton}`
                      : `${buttonClass}`
                    }`}
                    onClick={() => handleClick({ offer, label: mappedItem.buttonLabel })}
                  >
                    {mappedItem.buttonLabel}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </BackgroundTheme>
  );
};

export default PricingCardDynamic;
