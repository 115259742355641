/* eslint-disable no-restricted-syntax */
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import fetch from 'cross-fetch';
import { onError } from '@apollo/client/link/error';
import { persistCacheSync, SessionStorageWrapper } from 'apollo3-cache-persist';
import * as Sentry from '@sentry/nextjs';

export enum FetchPolicy {
  cacheFirst = 'cache-first',
  networkOnly = 'network-only',
  cacheOnly = 'cache-only',
  noCache = 'no-cache',
  standby = 'standby'
}

export enum ErrorPolicy {
  none = 'none',
  ignore = 'ignore',
  all = 'all',
}

// Agregamos un objeto para manejar múltiples endpoints
const APOLLO_ENDPOINTS = {
  default: process.env.NEXT_PUBLIC_GQL_ENDPOINT,
  checkout: process.env.NEXT_PUBLIC_BFF_CHECKOUT_URL,
};

const createApolloLink = (endpoint: string) => onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      const err = new Error(`[GraphQL error]: Message: ${message}`);
      Sentry.captureException(err);
    });
  }

  if (networkError) Sentry.captureException(new Error(`[Network error]: ${networkError}`));
}).concat(
  createHttpLink({
    uri: APOLLO_ENDPOINTS[endpoint] || APOLLO_ENDPOINTS.default, // Usamos el endpoint seleccionado o el por defecto
    fetch,
  }),
);

const cache = new InMemoryCache();

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const createApolloClient = (endpoint: string): ApolloClient<NormalizedCacheObject> => {
  if (typeof window !== 'undefined') {
    persistCacheSync({
      cache,
      storage: new SessionStorageWrapper(window.sessionStorage),
    });
  }

  const link = createApolloLink(endpoint);

  return new ApolloClient({
    ssrMode: true, // set to true for SSR
    link,
    cache,
  });
};

export const initializeApollo = (endpoint = 'default') => {
  if (!apolloClient || endpoint !== 'default') {
    apolloClient = createApolloClient(endpoint);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClient;

  return apolloClient;
};

export default initializeApollo;
