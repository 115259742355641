import { ReactNode } from 'react';
import { getColor } from '../../utils/colors';
import Patterns from './patterns';
import { EBackgroundPattern } from './model/BackgroundTheme.model';

export interface IBackground {
  children: ReactNode;
  pattern?: keyof typeof EBackgroundPattern;
  theme?: string;
}

const BackgroundTheme: React.FC<IBackground> = ({
  pattern = EBackgroundPattern.pattern1,
  theme,
  children,
}) => {
  const color = getColor(theme);
  const PatternComponent = Patterns[pattern];
  return (
    <div className="background-main relative">
      <div className="background">
        <PatternComponent className={`background__pattern ${color.text}`} />
      </div>
      {children}
    </div>
  );
};

export default BackgroundTheme;
