/* eslint-disable max-len */

interface IProps {
  className?: string;
}

const Pattern1: React.FC<IProps> = ({ className = '' }: IProps) => (
  <svg className={className} width="1920" height="857" viewBox="0 0 1920 857" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_577_19317)">
      <ellipse cx="2119.7" cy="531.864" rx="197.864" ry="197.864" transform="rotate(-90 2119.7 531.864)" fill="currentColor" fillOpacity="0.4" />
    </g>
    <mask id="mask0_577_19317" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1329" y="386" width="1671" height="673">
      <rect x="1329" y="386.375" width="1670.1" height="672" fill="url(#paint0_radial_577_19317)" />
    </mask>
    <g mask="url(#mask0_577_19317)">
      <rect x="1259.42" y="328.255" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1391.43" y="328.255" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1523.44" y="328.255" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1655.45" y="328.255" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1787.45" y="328.255" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1919.46" y="328.255" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1259.42" y="460.262" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1391.43" y="460.262" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1523.44" y="460.262" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1655.45" y="460.262" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1787.45" y="460.262" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1919.46" y="460.262" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1259.42" y="592.27" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1391.43" y="592.27" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1523.44" y="592.27" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1655.45" y="592.27" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1787.45" y="592.27" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1919.46" y="592.27" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1259.42" y="724.278" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1391.43" y="724.278" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1523.44" y="724.278" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1655.45" y="724.278" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1787.45" y="724.278" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1919.46" y="724.278" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1259.42" y="856.285" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1391.43" y="856.285" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1523.44" y="856.285" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1655.45" y="856.285" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1787.45" y="856.285" width="131.514" height="131.514" stroke="#C3C3C3" strokeOpacity="0.8" strokeWidth="0.494118" />
      <rect x="1919.21" y="856.038" width="132" height="131" fill="url(#paint1_linear_577_19317)" />
    </g>
    <g style={{ mixBlendMode: 'screen' }} filter="url(#filter1_f_577_19317)">
      <circle cx="121.5" cy="723.5" r="271.5" fill="url(#paint2_radial_577_19317)" fillOpacity="0.4" />
    </g>
    <mask id="mask1_577_19317" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-593" y="548" width="1671" height="672">
      <rect x="-593" y="548" width="1670.1" height="672" fill="url(#paint3_radial_577_19317)" />
    </mask>
    <g mask="url(#mask1_577_19317)">
      <rect x="-2.53895" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="129.469" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="261.476" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="393.484" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="525.492" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="657.5" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="789.507" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="921.515" y="489.88" width="131.514" height="131.514" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="-2.57716" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="129.423" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="261.423" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="393.423" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="525.423" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="657.423" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="789.423" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="921.423" y="621.888" width="131.506" height="109.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="-2.57716" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="129.423" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="261.423" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="393.423" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="525.423" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="657.423" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="789.423" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
      <rect x="921.423" y="731.888" width="131.506" height="149.506" stroke="#C3C3C3" strokeWidth="0.494118" />
    </g>
    <defs>
      <filter id="filter0_f_577_19317" x="1587.89" y="0.0520325" width="1063.62" height="1063.62" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="166.974" result="effect1_foregroundBlur_577_19317" />
      </filter>
      <filter id="filter1_f_577_19317" x="-257.898" y="344.102" width="758.796" height="758.796" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="53.949" result="effect1_foregroundBlur_577_19317" />
      </filter>
      <radialGradient id="paint0_radial_577_19317" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2164.05 722.375) rotate(90) scale(414.212 1029.43)">
        <stop stopColor="#D9D9D9" stopOpacity="0.4" />
        <stop offset="0.793667" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint1_linear_577_19317" x1="2051.21" y1="987.038" x2="1918.23" y2="857.05" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0.5" />
      </linearGradient>
      <radialGradient id="paint2_radial_577_19317" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(121.5 723.5) rotate(90) scale(271.5)">
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint3_radial_577_19317" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-55.0001 884) rotate(90.3215) scale(534.634 1328.71)">
        <stop stopColor="#D9D9D9" stopOpacity="0.6" />
        <stop offset="0.468518" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default Pattern1;
