import { Link, RichTextBlock } from 'prismic-reactjs';
import { SliceCheckout, Image } from 'types/prismic.type';

export enum EPricingVariations {
  pricingCards = 'pricingCards',
  pricingCardDynamic = 'pricingCardDynamic',
}

export type IPricingCards = SliceCheckout & {
  slice: {
    primary: {
      isAsync?: boolean;
      title?: string;
      description?: RichTextBlock[];
      checkoutButtonLabel?: string;
      checkoutLastPlacesLabel?: string;
      checkoutSoldoutLabel?: string;
      color?: string;
      titleAlt?:string;
      urlAlt?:Link;
      btnLabelAlt?:string;
      image?:Image
    }
    items?: {
      featured?: boolean;
      checkoutVersion?: 'new' | 'legacy';
      installments?: number;
      title?: string;
      description?: RichTextBlock[];
    }[];
  }
}

export type IPricingCardDynamic = {
  slice: {
    primary: {
      title?: string;
      description?: RichTextBlock[];
      color?: string;
      titleAlt?:string;
      urlAlt?:Link;
      btnLabelAlt?:string;
      image?:Image
    }
    items?: {
      wareId:number;
      token: string;
      showcase?: number;
      highlighted?: boolean;
      highlightedLabel?: string;
      title?: string;
      description?: string;
      type?: string;
      logo?:Image;
      pill?: string;
      total?: RichTextBlock[];
      discount?: string;
      installments?: string;
      installmentsAmount?: string;
      totalFinal?: string;
      buttonLabel?: string;
      buttonVariant?: string;
    }[];
  }
}

export interface IPlan {
  id: string;
  billingRange: string;
  totalAmount: number;
  subtotal: number;
  discount: number;
  nominalDiscountAmount: number;
  financialInstallments?: number | null;
  installment: number;
  financialAmount: number;
  amount: number;
  remarked: string;
  checkoutUrl: string;
  showcaseOrder: number;
}

export interface IOfferShowcase {
  name: string;
  startAt: string;
  endAt: string;
  ware: {
    product: {
      cluster: {
        alias: string;
        vertical: {
          id: string;
          name: string;
        };
        businessUnit: {
          id: string;
          name: string;
        };
      };
    }[];
  };
  plans: IPlan[];
}
